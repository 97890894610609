import { useCallback, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { activate } from "../../../protocols";

import { useEventListener, useLocalStorage } from "../../../hooks";

import { useAuth } from "../../../context/auth";

import { DotsLoadingComponent, InputComponent } from "../../ui";

import theme from "../../../theme";
import {
  ButtonBlock,
  CardWrapper,
  FormSubtitle,
  FormTitle,
  SubmitButton,
  SubmitButtonLoadingCover,
  Wrapper,
} from "../RegistrationForm/style";

const ActivationForm = ({ clientId }) => {
  const { language, t } = i18next;
  const { setItem } = useLocalStorage();
  const { getUserData, onChangeIsAuth } = useAuth();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    activation_code: Yup.string().required(t("required")),
    password: Yup.string().min(6, t("must_field_characters")).required(t("required")),
    confirmationPassword: Yup.string()
      .min(6, t("must_field_characters"))
      .required(t("required"))
      .oneOf([Yup.ref("password"), null], t("passwords_must_match")),
  });

  const handleFormSubmit = async values => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const activationResult = await activate({ ...values, client_id: clientId, language });

      setItem("token", activationResult?.data?.token);

      await getUserData(activationResult?.data);
      onChangeIsAuth(true);

      setIsSubmitting(false);
      navigate("/");
    } catch (error) {
      setFieldError("login", error.response.data.message);
      setIsSubmitting(false);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      activation_code: "",
      password: "",
      confirmationPassword: "",
    },
    validationSchema,
    onSubmit: handleFormSubmit,
    validateOnBlur: true,
  });

  const handleKeyPress = useCallback(
    e => {
      if (e.key === "Enter" || e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useEventListener("keypress", handleKeyPress);

  return (
    <Wrapper>
      <CardWrapper>
        <FormTitle>{t("activation_title")}</FormTitle>

        <FormSubtitle>{t("activation_code_send_to_email")}</FormSubtitle>

        <InputComponent
          value={values.activation_code}
          onChange={handleChange("activation_code")}
          type="text"
          placeholder={t("enter_recieved_code")}
          label={t("enter_recieved_code")}
          error={errors.activation_code}
          onBlur={handleBlur("activation_code")}
          touched={touched.activation_code}
          required
        />

        <InputComponent
          value={values.password}
          onChange={handleChange("password")}
          type="password"
          placeholder={t("come_up_your_pass")}
          label={t("password_text")}
          error={errors.password}
          onBlur={handleBlur("password")}
          touched={touched.password}
          required
        />

        <InputComponent
          value={values.confirmationPassword}
          onChange={handleChange("confirmationPassword")}
          type="password"
          placeholder={t("confirmation_activation_password")}
          label={t("password_text")}
          error={errors.confirmationPassword}
          onBlur={handleBlur("confirmationPassword")}
          touched={touched.confirmationPassword}
          required
        />

        <ButtonBlock>
          <SubmitButton onClick={handleSubmit} type="button">
            {t("activate")}
            {isSubmitting && (
              <SubmitButtonLoadingCover>
                <DotsLoadingComponent color={theme.palette.black} />
              </SubmitButtonLoadingCover>
            )}
          </SubmitButton>
        </ButtonBlock>
      </CardWrapper>
    </Wrapper>
  );
};

export default ActivationForm;

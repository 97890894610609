import i18next from "i18next";
import { useSelector } from "react-redux";
import { useToggle } from "../../../hooks";
import theme from "../../../theme";
import { checkIsAllowedCancel } from "../../../utils";
import { DotsLoadingComponent } from "../../ui";
import { CancelButton, Wrapper } from "./style";

const OrderCopyAndCancelComponent = ({ onCancelOrder, isShowInSmallScreen = false }) => {
  const { t } = i18next;
  const {
    orderData: { booking_status, order_status },
  } = useSelector(state => state.order);

  const {
    isTrue: isLoading,
    set: { on, off },
  } = useToggle();

  const isAllowedCancel = checkIsAllowedCancel(booking_status, order_status);

  const cancelOrderCallback = ({ error }) => {
    off();
  };

  const handleCancelOrder = async () => {
    if (!isLoading) {
      on();
      onCancelOrder(cancelOrderCallback);
    }
  };

  if (!isAllowedCancel) return null;

  return (
    <Wrapper $isShowInSmallScreen={isShowInSmallScreen}>
      <div>
        <CancelButton onClick={handleCancelOrder} $isLoading={isLoading}>
          {isLoading ? <DotsLoadingComponent color={theme.palette.black} /> : t("void_order")}
        </CancelButton>
      </div>
    </Wrapper>
  );
};

export default OrderCopyAndCancelComponent;

import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.paddings(20, 10),
  width: 320,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
  position: "relative",

  [theme.down("xl")]: {
    width: 280,
  },

  [theme.down("md")]: {
    width: 210,
  },

  [theme.down("sm")]: {
    width: "100%",
  },
}));

export const PricesBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 10,
  alignItems: "center",
  // position: "sticky",
  // top: 60,
}));

export const SubmitButton = styled("button")(({ theme }) => ({
  ...theme.paddings(5, 10),
  width: 170,
  height: 42,
  //color: "#fff",
  backgroundColor: theme.palette.searchButtonBg,
  border: "none",
  fontSize: 14,
  cursor: "pointer",
  transition: "all 0.3s ease-out",

  "&:hover": {
    filter: "brightness(90%)",
  },
}));

export const Timelimit = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  gap: 3,
  fontSize: 18,

  "& > span": {
    color: "#484848",
    fontSize: 12,
    lineHeight: "14px",
  },
}));

export const TariffItem = styled("div")(({ theme }) => ({
  ...theme.paddings(10, 0),
  ...theme.mixins.flexColumn,
  alignItems: "center",
  justifyContent: "center",
  gap: 10,
  width: "100%",
}));

export const TariffsButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  fontSize: 16,
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#777",
  gap: 5,

  "& > span": {
    display: "flex",
    fontSize: 16,
  },

  "& > span.active": {
    transform: "rotate(180deg)",
  },
}));

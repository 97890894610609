import { useEffect } from "react";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { decodeParamsFromUrl, decodeSearchId, getDataForFilter, getPriceRange } from "../../utils";

import { ContainerComponent, DotsLoadingComponent, FiltersComponent, ResultsComponent } from "../../components";

import { t } from "i18next";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../../constants";
import { searchFlight } from "../../protocols";
import { setSearchBlockCompact, setShowSearch } from "../../store/appConfigsSlice";
import { setFilters, setRecommendations } from "../../store/resultDataSlice";
import { setAllFilterParams, setFiltersData } from "../../store/resultFiltersSlice";
import { setSearchParams } from "../../store/searchParamsSlice";
import theme from "../../theme";
import { Block, ErrorBlock, LoadingBlock, SessionBlock, Wrapper } from "./style";

const ResultPage = () => {
  const { searchId } = useParams();
  const location = useLocation();

  const searchParams = useSelector(state => state.searchParams);
  const { currency, language } = useSelector(state => state.appConfigs);
  const { isLoad, recommendations, session } = useSelector(state => state.resultData);
  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  useEffect(() => {
    getParams(searchParams);
  }, [location]);

  useEffect(() => {
    if (isLoad && !error) {
      dispatch(setShowSearch(true));
    }

    if (isLoad && error) {
      dispatch(setShowSearch(false));
    }
    // dispatch(
    //     setAllLoadingData({
    //         isSearchLoading: false,
    //         searchData: null,
    //     })
    // );
  }, [isLoad, error, dispatch]);

  //   useEffect(() => {
  //     if (recommendations.length > 0) {
  //         const priceRange = getPriceRange(recommendations, currency);

  //         dispatch(setFiltersData({ priceRange }));
  //         dispatch(setAllFilterParams(decodeParamsFromUrl(priceRange)));
  //     }
  // }, [currency, recommendations]);

  const getParams = async () => {
    dispatch(setSearchBlockCompact(true));
    dispatch(setShowSearch(false));
    dispatch(setRecommendations({ isLoad: false }));
    if (searchParams.routes[0].from) {
      getResult(readySearchParams(searchParams));
    } else {
      try {
        const { searchParams: decodedSearchParams } = await decodeSearchId(searchId, language);

        dispatch(
          setSearchParams({
            flightType: decodedSearchParams.flightType,
            cabin: decodedSearchParams.cabin,
            passengers: decodedSearchParams.passengers,
            routes:
              decodedSearchParams.flightType === "OW"
                ? [
                    {
                      from: decodedSearchParams.searchRoutes[0].from,
                      to: decodedSearchParams.searchRoutes[0].to,
                      date: decodedSearchParams.searchRoutes[0].date,
                    },
                    {
                      from: decodedSearchParams.searchRoutes[0].from,
                      to: decodedSearchParams.searchRoutes[0].to,
                      date: null,
                    },
                  ]
                : decodedSearchParams.searchRoutes,
          })
        );

        getResult(readySearchParams(decodedSearchParams));
      } catch (error) {
        setError(error);
      }
    }
  };

  const getResult = searchParams => {
    searchFlight(searchParams, language).then(response => {
      if (response?.error_key) {
        setError(response);
        dispatch(setRecommendations({ isLoad: true, hasData: false }));
      } else {
        dispatch(setSearchParams({ hasData: true }));

        let { flights, included, session_id } = response;

        if (flights.length > 0) {
          let data = [];

          flights.forEach(flight => {
            if (flight.validating_supplier) {
              data = [flight.validating_supplier, ...data];
            }
          });

          const [filterTemplate, routesData] = getDataForFilter(flights);
          const airlinesData = _.uniq(data);
          const priceRange = getPriceRange(flights, currency);

          dispatch(setFiltersData({ airlinesData, priceRange, routesData }));
          dispatch(setAllFilterParams(decodeParamsFromUrl(priceRange)));
          dispatch(
            setFilters({
              type: "routesData",
              status: filterTemplate,
            })
          );
        }

        dispatch(
          setRecommendations({
            recommendations: flights,
            included,
            session: session_id,
            isLoad: true,
          })
        );
      }
    });
  };

  const readySearchParams = searchData => {
    let params = {
      cabin: searchData.cabin,
      passengers: searchData.passengers,
      flightType: searchData.flightType,
      routes: [],
    };

    if (searchData.searchRoutes) {
      params.routes = searchData.routes;
    } else {
      if (searchData.flightType !== FLIGHT_TYPE_CF) {
        params.routes.push({
          from: searchData.routes?.[0]?.from?.code,
          to: searchData.routes?.[0].to?.code,
          date: searchData.routes?.[0]?.date,
        });

        if (searchData.routes?.[1]?.date) {
          params.routes.push({
            from: searchData.routes?.[0]?.to?.code,
            to: searchData.routes?.[0].from?.code,
            date: searchData.routes?.[1]?.date,
          });
          params.flightType = FLIGHT_TYPE_RT;
        }
      } else {
        searchData.routes.forEach(route => {
          if (route.date) {
            params.routes.push({
              from: route.from?.code,
              to: route.to?.code,
              date: route.date,
            });
          }
        });
      }
    }

    if (searchData.flightType === FLIGHT_TYPE_RT && params.routes.length < 2) {
      params.flightType = FLIGHT_TYPE_OW;
    }

    return params;
  };

  return (
    <Wrapper>
      <ContainerComponent>
        {!isLoad && (
          <LoadingBlock>
            <DotsLoadingComponent color={theme.palette.black} />
          </LoadingBlock>
        )}

        {isLoad && error && (
          <ErrorBlock>
            <div>
              {t("code")}: {error.error_key} ({error.error_code})
            </div>
            <div>
              {t("message")}: {error.error_desc}
            </div>
          </ErrorBlock>
        )}

        {isLoad && !error && (
          <>
            <SessionBlock>Session: {session}</SessionBlock>

            <Block>
              {recommendations.length > 0 && <FiltersComponent />}
              <ResultsComponent />
            </Block>
          </>
        )}
      </ContainerComponent>
    </Wrapper>
  );
};

export default withTranslation()(ResultPage);

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { book, prebook } from "../../protocols";

import { setPrebookData } from "../../store/prebookSlice";

import { setShowSearch } from "../../store/appConfigsSlice";
import { getFlightDocsType, getRoutesCountries, normalizePhone } from "../../utils";

import { withTranslation } from "react-i18next";
import { BookingComponent, ContainerComponent, DotsLoadingComponent } from "../../components";
import theme from "../../theme";
import { LoadingBlock } from "../ResultPage/style";

const BookingPage = () => {
  const {
    state: { rec_id, session_id, passengers, searchData },
  } = useLocation();
  const navigate = useNavigate();

  const { isLoad, recId } = useSelector(state => state.prebook);
  const { currency, language } = useSelector(state => state.appConfigs);
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (rec_id && session_id) {
      prebook({ rec_id, session_id, language })
        .then(response => {
          const { routes, rec_id, total_price, fare_rules, included, partner_fees, client_fee, validating_supplier } =
            response;

          const routesCountries = getRoutesCountries(routes, included);
          const flightDocsType = getFlightDocsType(routesCountries);

          dispatch(
            setPrebookData({
              routes,
              recId: rec_id,
              totalPrice: total_price,
              partnerFees: partner_fees,
              clientFees: client_fee,
              fareRules: fare_rules,
              included: included,
              flightType: flightDocsType,
              validatingSupplier: validating_supplier,
              loadStatus: true,
            })
          );
        })
        .catch(e => {
          setError(e.response);
        });
    }

    dispatch(setShowSearch(false));

    return () => {
      dispatch(
        setPrebookData({
          routes: [],
          fareRules: [],
          included: [],
          recId: null,
          totalPrice: null,
          isLoad: false,
          loadStatus: false,
        })
      );

      dispatch(setShowSearch(true));
    };
  }, []);

  // useEffect(() => {
  //   if (isLoad && !isLoadingBook) {
  //     dispatch(setShowLoading(false));
  //   } else {
  //     if (isLoadingBook) {
  //       dispatch(setBookingLoading(true));
  //     }
  //     dispatch(setShowLoading(true));
  //   }
  // }, [isLoad, isLoadingBook]);

  const redirectToResult = () => {
    navigate("/result", { state: searchData });
  };

  const handleBookClick = data => {
    const passengers = [];

    data.passengers.forEach((passenger, index) => {
      const {
        name,
        surname,
        middle_name,
        citizenship,
        gender,
        type,
        document_type,
        document_number,
        email,
        date_of_birth,
        phone,
        expiration_date,
      } = passenger;

      const passengerPhone = type === "adt" ? normalizePhone(phone) : normalizePhone(data.passengers[0].phone);
      const passengerEmail = type === "adt" ? email : data.passengers[0].email;
      const expirationDate = expiration_date ? expiration_date.split(".").reverse().join("-") : "2099-12-31";

      passengers[index] = {
        index,
        name: name,
        surname: surname,
        middle_name: middle_name || "",
        citizenship: citizenship,
        gender: gender,
        type: type,
        document_type: document_type,
        document_number: document_number,
        email: passengerEmail,
        phone: passengerPhone,
        expiration_date: expirationDate,
        date_of_birth: date_of_birth.split(".").reverse().join("-"),
      };
    });

    const params = {
      passengers,
      rec_id: recId,
      partner_fees: data.partnerFees || 0,
      session_id: session_id,
      payer_phone: normalizePhone(data.payer_phone),
      payer_email: data.payer_email,
      meta: {
        currency: currency,
        language,
      },
    };

    book(params)
      .then(response => {
        setOrder(response?.data.data);
        setRedirect(true);
      })
      .catch(error => {
        setError(error.response.data);
      });
  };

  if (error) {
    return null; // TODO: Error modal show
  }

  if (!rec_id || !session_id) {
    navigate("/");
    return null;
  }

  if (!isLoad)
    return (
      <div>
        <ContainerComponent>
          <LoadingBlock>
            <DotsLoadingComponent color={theme.palette.black} />
          </LoadingBlock>
        </ContainerComponent>
      </div>
    );

  if (redirect) {
    navigate(`/order/${order?.order_id}/${session_id}`);
    return null;
  }

  return (
    <BookingComponent
      sessionId={session_id}
      passengers={passengers}
      handleBookClick={handleBookClick}
      redirectToResult={redirectToResult}
    />
  );
};

export default withTranslation()(BookingPage);

import { useCallback, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { requestResetPassword } from "../../../protocols";

import { useEventListener } from "../../../hooks";

import { DotsLoadingComponent, InputComponent } from "../../ui";

import theme from "../../../theme";
import {
  BackButton,
  BackButtonBlock,
  BackButtonLabel,
  ButtonBlock,
  CardWrapper,
  FormSubtitle,
  FormTitle,
  SubmitButton,
  SubmitButtonLoadingCover,
  Wrapper,
} from "../../auth/RegistrationForm/style";

const ForgotPasswordForm = () => {
  const { t } = i18next;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);

  const validationSchema = Yup.object().shape({
    login: Yup.string().trim().email(t("enter_valid_mail")).required(t("required")),
  });

  const handleFormSubmit = async values => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const loginResult = await requestResetPassword(values);

      if (loginResult?.data?.error_code) {
        setFieldError("login", loginResult.error_desc);
      } else {
        setStep(1);
      }
    } catch (error) {
      console.log({ error });
      setFieldError("login", error.response.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      login: "",
      url: `${window.location.origin}/reset-password`,
    },
    validationSchema,
    onSubmit: handleFormSubmit,
    validateOnBlur: true,
  });

  const handleKeyPress = useCallback(
    e => {
      if (e.key === "Enter" || e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useEventListener("keypress", handleKeyPress);

  return (
    <Wrapper>
      <CardWrapper>
        <FormTitle>{!step ? t("reset_password") : t("mail_sent")}</FormTitle>

        <FormSubtitle>{!step ? t("password_recovery_text") : t("password_recovery_confirm_text")}</FormSubtitle>

        {!step && (
          <>
            <InputComponent
              value={values.login}
              onChange={handleChange("login")}
              type="email"
              placeholder={t("enter_email")}
              label={t("email")}
              error={errors.login}
              onBlur={handleBlur("login")}
              touched={touched.login}
              required
            />

            <ButtonBlock>
              <SubmitButton onClick={handleSubmit} type="button">
                {t("send")}
                {isSubmitting && (
                  <SubmitButtonLoadingCover>
                    <DotsLoadingComponent color={theme.palette.black} />
                  </SubmitButtonLoadingCover>
                )}
              </SubmitButton>
            </ButtonBlock>

            <BackButtonBlock>
              <BackButtonLabel>{t("new_customer")}</BackButtonLabel>
              <BackButton>
                <Link to="/registration">{t("registration_text")}</Link>
              </BackButton>
            </BackButtonBlock>
          </>
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default ForgotPasswordForm;

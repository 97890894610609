// headerBg: "rgba(225, 242, 250, 1)",
const palette = {
  headerBg: "#ffffff",
  footerBg: "#1A2130", //1D3557
  searchFormBg: "rgba(0, 0, 0, .5)",
  searchButtonBg: "#F5D000",
  hoverButtonBg: "#FFC64D",
  removeRouteButtonBg: "#ecc800",

  white: "#ffffff",
  black: "#000000",
};

export default palette;

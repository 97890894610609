import styled from "styled-components";

import { ContainerComponent } from "../../ui";

export const Wrapper = styled("header")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  position: "sticky",
  left: 0,
  top: 0,
  width: "100%",
  backgroundColor: theme.palette.headerBg,
  color: "#000",
  height: 80,
  zIndex: 152,
}));

export const StyledContainer = styled(ContainerComponent)(({ theme }) => ({
  ...theme.mixins.flexCenterBetween,
  flexGrow: 1,
}));

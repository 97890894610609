import styled from "styled-components";

import { FLIGHT_TYPE_CF } from "../../../constants";

export const Wrapper = styled("div")(({ theme, flightType }) => ({
  ...theme.mixins.flexColumn,
  ...(flightType !== FLIGHT_TYPE_CF ? { borderRadius: "0 4px 4px 0" } : { borderRadius: 4 }),
  backgroundColor: theme.palette.white,
  width: 266,
  height: 60,
  position: "relative",
  cursor: "pointer",

  [theme.down("xl")]: {
    width: 226,
  },

  [theme.down("lg")]: {
    width: 186,
  },

  [theme.down("md")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 374 : 248,
  },

  [theme.down("sm")]: {
    width: "100%",
    borderRadius: 4,
  },
}));

export const ClassBlock = styled("div")(({ theme }) => ({
  ...theme.paddings(4, 15, 0),
  fontSize: 11,
  color: "#b7b7b7",
}));

export const PassengersCountBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  paddingLeft: 15,
  paddingTop: 2,
}));

export const PassengerCount = styled("div")(({ theme }) => ({
  ...theme.paddings(0, 10, 0, 15),
  fontSize: 14,
  position: "relative",

  "& > svg": {
    position: "absolute",
    left: -14,
    top: -10,
  },
}));

export const DropdownIcon = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  padding: 4,
  position: "absolute",
  right: 5,
  top: 18,
  color: "#808080",
  transition: "all .3s ease",
  transform: "rotate(0deg)",

  "&.active": {
    transition: "all .3s ease",
    transform: "rotate(180deg)",
  },
}));

export const DropdownPassengersBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 5,
}));

export const DropdownPassengerItem = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
}));

export const DropdownPassengerItemTitleAndDescription = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  flexGrow: 1,

  "& .title": {
    fontSize: 12,
    color: "#000",
  },

  "& .description": {
    fontSize: 10,
    color: "#999",
  },
}));

export const CounterBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  fontSize: 30,
  color: "#333",

  "& span": {
    fontSize: 14,
    width: 20,
    textAlign: "center",
  },

  "& > svg": {
    cursor: "pointer",
    color: "#333",

    "&.disabled": {
      cursor: "default",
      color: "#9f9f9f",
    },
  },
}));

export const ClassesBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 5,
  paddingTop: 15,
}));

export const ClassButton = styled("button")(({ theme, position }) => ({
  ...(position ? { gridColumn: position } : {}),
  backgroundColor: "transparent",
  color: "#999",
  border: "1px solid #ccc",
  borderRadius: 4,
  height: 32,
  cursor: "pointer",

  "&.active": {
    backgroundColor: theme.palette.searchButtonBg,
    borderColor: theme.palette.hoverButtonBg,
    color: theme.palette.black,
  },
}));

import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const animateNext = keyframes`
  0% {
    transform: translateX(10px);
  }

  to {
    transform: translateX(0);
  }
`;

const animatePrev = keyframes`
  0% {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0);
  }
`;

export const CalendarHeader = styled("div")(({ theme, centered }) => ({
  ...theme.mixins.flexCenterBetween,
  ...(centered ? { justifyContent: "center" } : {}),
  borderBottom: "1px solid #b8b8b8",
  padding: 10,
}));

export const CalendarHeaderText = styled("span")(({ theme }) => ({
  fontSize: 14,
  color: "#333",
  lineHeight: "16px",
}));

export const CalendarHeaderButton = styled("button")(({ theme }) => ({
  width: "100%",
  height: 28,
  border: "1px solid",
  borderColor: "#e5eff8 #e5eff8 #bdd6ed",
  backgroundColor: "#e5eff8",
  color: "#333",
  padding: 0,
  margin: 0,
  maxWidth: 185,
  fontWeight: 600,
  cursor: "pointer",
  borderRadius: 4,
  fontSize: 12,
}));

export const CalendarBody = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const CalendarMonthsNamesBlock = styled("div")(({ theme }) => ({
  padding: 10,
  backgroundColor: "#f2f2f2",
  width: 88,
}));

export const CalendarMonthsNamesList = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 9,
  flexGrow: 1,
}));

export const CalendarMonthName = styled("button")(({ theme, type }) => ({
  border: "none",
  background: "transparent",
  padding: 0,
  margin: 0,
  fontSize: 13,
  textAlign: "left",
  lineHeight: "16px",
  cursor: "pointer",

  "&.active": {
    fontWeight: 600,
    color: type === "from" ? theme.palette.searchButtonBg : theme.palette.footerBg,
  },

  "&.year": {
    cursor: "default",
  },
}));

export const CalendarMonthsBlock = styled("div")(({ theme }) => ({
  position: "relative",
  width: 640,
  padding: 15,

  [theme.down("md")]: {
    width: 335,
  },
}));

export const CalendarPrevButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  left: 15,
  zIndex: 50,
  width: 25,
  height: 25,
  padding: 0,
  backgroundColor: "#e0e0e0",
  border: "1px solid",
  borderColor: "#e0e0e0 #e0e0e0 #c7c7c7",
  borderRadius: 4,
  color: "#616161",
  cursor: "pointer",

  "&:disabled": {
    cursor: "default",
    opacity: 0.5,
    pointerEvents: "none",
  },

  "&:hover": {
    backgroundColor: "#d3d3d3",
    color: "#545454",
  },
}));

export const CalendarNextButton = styled(CalendarPrevButton)(({ theme }) => ({
  left: "auto",
  right: 15,
}));

export const CalendarMonths = styled("div")(
  ({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",

    [theme.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  }),
  css`
    &.animate-next {
      animation: ${animateNext} 0.3s forwards, ${fadeIn} 0.2s forwards;
    }

    &.animate-prev {
      animation: ${animatePrev} 0.3s forwards, ${fadeIn} 0.2s forwards;
    }
  `
);

export const CalendarMonthBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  padding: "0 7.5px",
}));

export const CalendarMonthTitle = styled("span")(({ theme }) => ({
  width: "100%",
  fontSize: 13,
  fontWeight: 600,
  color: "#333",
  lineHeight: "20px",
  textAlign: "center",
  padding: "5px 0 10px",
}));

export const CalendarMonthWeeksBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(7, 38px)",
  gap: 4,
  paddingBottom: 4,
  textTransform: "uppercase",
}));

export const CalendarMonthWeek = styled("span")(({ theme }) => ({
  textAlign: "center",
  color: "#b7b7b7",
  fontSize: 13,
  lineHeight: "20px",
}));

export const CalendarMonthDaysBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(7, 38px)",
  gap: 4,
}));

export const CalendarMonthDay = styled("button")(({ theme, position, type, $selectedIndex }) => ({
  ...theme.mixins.flexCenterCenter,
  lineHeight: 1,
  width: 38,
  height: 38,
  fontSize: 14,
  backgroundColor: "transparent",
  border: "2px solid transparent",
  borderRadius: 4,

  ...(position
    ? {
        gridColumn: `${position} / ${position + 1}`,
      }
    : {}),

  "&:hover": {
    borderColor: type === "from" ? theme.palette.searchButtonBg : theme.palette.black,
    backgroundColor: type === "from" ? "#F5D000a" : "#000000a",
    cursor: "pointer",
  },

  "&.selected": {
    backgroundColor: $selectedIndex > -1 && $selectedIndex !== 1 ? theme.palette.hoverButtonBg : theme.palette.footerBg,
    borderColor: $selectedIndex > -1 && $selectedIndex !== 1 ? theme.palette.searchButtonBg : theme.palette.black,
    color: "#fff",
  },

  "&.active": {
    backgroundColor: type === "from" ? theme.palette.hoverButtonBg : theme.palette.footerBg,
    borderColor: type === "from" ? theme.palette.searchButtonBg : theme.palette.black,
    color: "#fff",
  },

  "&.disabled": {
    color: "#9e9e9e",
    cursor: "default",
  },
}));

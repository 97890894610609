import { useEffect, useState } from "react";

import i18next from "i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { EPA, PDC } from "../../constants";

import { checkInAccessToPaymentStatus } from "../../utils";

import { getOrder, getPaymentSystems, orderCancel, ticketing } from "../../protocols";

import { setShowSearch } from "../../store/appConfigsSlice";
import { setAvailablePaymentMethods, setOrderData, setPaymentMethod } from "../../store/orderSlice";

import { withTranslation } from "react-i18next";
import {
  ContainerComponent,
  DCPaymentModalComponent,
  DotsLoadingComponent,
  OrderComponent,
  VisaPaymentModalComponent,
} from "../../components";
import theme from "../../theme";
import { LoadingBlock } from "./style";

const OrderPage = () => {
  const { t, language } = i18next;

  const { isLoad, paymentMethod, availablePaymentMethods, orderData } = useSelector(state => state.order);

  const { orderId, sessionId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [responseError, setResponseError] = useState(null);
  const [isChangedStatus, setIsChangedStatus] = useState(false);
  const [dcPaymentModal, setDcPaymentModal] = useState(null);
  const [visaPaymentModal, setVisaPaymentModal] = useState(null);

  useEffect(() => {
    // dispatch(setShowLoading(true));

    getOrderData();

    dispatch(setShowSearch(false));

    return () => {
      setOrderData({
        orderData: [],
        loadStatus: false,
      });

      setAvailablePaymentMethods({
        methods: [],
        loadStatus: false,
      });

      setPaymentMethod({
        method: null,
      });
    };
  }, [location]);

  const sendPayment = callback => {
    const methodData = availablePaymentMethods.find(method => method.code === paymentMethod);

    const { currency, order_id, session_id, total_price } = orderData;

    const params = {
      order_id,
      session_id,
      total_price: {
        [currency]: total_price,
      },
      payment_systems_id: methodData.id,
      return_url: window.location.href,
      fail_url: window.location.href,
    };

    ticketing(params)
      .then(response => {
        if (paymentMethod === PDC) {
          const { pay_url, payform } = response.data;

          setDcPaymentModal({
            showPopup: true,
            handleClose: () => setDcPaymentModal(null),
            pay_url,
            payform,
          });

          callback();
        } else if (paymentMethod === EPA) {
          setVisaPaymentModal({
            showPopup: true,
            handleClose: () => setVisaPaymentModal(null),
            data: response.data?.pay_url,
          });

          callback();
        } else if (paymentMethod === "BALANCE") {
          window.location.reload();
        } else {
          window.location.href = response.data?.pay_url;
        }
      })
      .catch(error => {
        console.log({ error });
        setResponseError("Произошла ошибка!");
      });
  };

  const getOrderData = (callback = () => {}) => {
    getOrder({
      order_id: orderId,
      session_id: sessionId,
      language,
    })
      .then(response => {
        const { booking_status, order_status } = response;
        const isAccessToPayment = checkInAccessToPaymentStatus(booking_status, order_status);

        if (isAccessToPayment) {
          getPaymentSystems("order").then(response => {
            dispatch(
              setAvailablePaymentMethods({
                methods: response,
                loadStatus: true,
              })
            );

            const actualMethod = _.first(response);

            dispatch(setPaymentMethod({ method: actualMethod ? actualMethod.code : null }));
          });
        } else {
          dispatch(
            setAvailablePaymentMethods({
              loadStatus: true,
            })
          );
        }

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        dispatch(
          setOrderData({
            orderData: response,
            loadStatus: true,
          })
        );

        callback({ error: false });
      })
      .catch(error => {
        setResponseError({
          error_code: error.response.data.code,
          error_desc: error.response.data.message,
          custom_error: t("do_not_find_order_message"),
        });

        callback({ error: true });
      });
  };

  const cancelOrder = callback => {
    const { session_id, order_id, key } = orderData;

    const params = {
      order_id,
      session_id,
      key,
      language,
    };

    orderCancel(params).then(() => {
      getOrderData(callback);
    });
  };

  if (!isLoad) {
    return (
      <div>
        <ContainerComponent>
          <LoadingBlock>
            <DotsLoadingComponent color={theme.palette.black} />
          </LoadingBlock>
        </ContainerComponent>
      </div>
    );
  }

  return (
    <>
      <OrderComponent
        availablePaymentMethods={availablePaymentMethods}
        sendPayment={sendPayment}
        partnerCode={orderData?.partner_code}
        setIsChangedStatus={setIsChangedStatus}
        isChangedStatus={isChangedStatus}
        cancelOrder={cancelOrder}
      />

      <DCPaymentModalComponent
        showPopup={dcPaymentModal?.showPopup}
        handleClose={dcPaymentModal?.handleClose}
        payUrl={dcPaymentModal?.payment_link}
        payform={dcPaymentModal?.payform}
      />

      <VisaPaymentModalComponent
        showPopup={visaPaymentModal?.showPopup}
        handleClose={visaPaymentModal?.handleClose}
        data={visaPaymentModal?.data}
      />
    </>
  );
};

export default withTranslation()(OrderPage);

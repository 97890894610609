import { useState } from "react";

import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import CircularLoading from "../CircularLoading";

import { PaginationBlock, PaginationButton, Table as StyledTable, TableContainer } from "./style";

const Table = ({
  columns,
  data,
  minWidth,
  isClickableRow,
  onClickRow,
  onChangePage,
  prevButtonDisabled,
  nextButtonDisabled,
}) => {
  const [loadingButton, setLoadingButton] = useState(null);

  const handleChangePage = async pageType => {
    setLoadingButton(pageType);
    await onChangePage(pageType);
    setLoadingButton(null);
  };

  return (
    <>
      <TableContainer>
        <StyledTable minWidth={minWidth} isClickableRow={isClickableRow}>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key}>{column.label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.map((row, index) => (
              <tr key={index} onClick={() => onClickRow?.(row)}>
                {columns.map(column => (
                  <td key={column.key}>{column.render ? column.render(row ?? {}) : row[column.key]?.toString()}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>

      {(!prevButtonDisabled || !nextButtonDisabled) && (
        <PaginationBlock>
          <PaginationButton disabled={prevButtonDisabled} onClick={() => handleChangePage("prev")}>
            {loadingButton === "prev" ? <CircularLoading size="16px" /> : <BiChevronLeft />}
          </PaginationButton>

          <PaginationButton disabled={nextButtonDisabled} onClick={() => handleChangePage("next")}>
            {loadingButton === "next" ? <CircularLoading size="16px" /> : <BiChevronRight />}
          </PaginationButton>
        </PaginationBlock>
      )}
    </>
  );
};

export default Table;

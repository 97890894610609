import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  gap: 20,
}));

export const LanguageCurrency = styled("div")(({ theme }) => ({
  paddingLeft: "5px",
  textTransform: "uppercase",
  fontSize: "17px",
}));

export const IconButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "50%",
  color: "#000",
  fontSize: 30,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const SettingsBlock = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const Items = styled("div")(({ theme }) => ({
  display: "block",
  flexDirection: "column",
  textAlign: "center",
  marginBottom: 15,

  "&:nth-last-of-type(1)": {
    marginBottom: 0,
  },
}));

export const UserSection = styled("button")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(0, 10),
  alignItems: "end",
  backgroundColor: "transparent",
  border: "none",
  color: "#000",
  fontWeight: "bold",
  marginBottom: 5,
  fontSize: 14,
  letterSpacing: 1,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const UserBalance = styled("span")(({ theme }) => ({
  fontSize: 16,
}));

export const UserCredit = styled("span")(({ theme }) => ({
  fontSize: 12,
}));

export const UserName = styled("span")(({ theme }) => ({
  fontSize: 16,
}));

export const GroupTitle = styled("div")(({ theme }) => ({
  ...theme.paddings(0, 10),
  color: "#000",
  fontWeight: "bold",
  marginBottom: 5,
  fontSize: 14,
}));

export const Item = styled("div")(({ theme }) => ({
  ...theme.paddings(5, 10),
  display: "flex",
  justifyContent: "space-between",
  color: "#151515",

  "&:hover": {
    color: theme.palette.searchButtonBg,
    cursor: "pointer",
  },
}));

export const ItemLink = styled(Link)(({ theme }) => ({
  ...theme.paddings(5, 10),
  display: "flex",
  justifyContent: "space-between",
  color: "#151515",

  "&:hover": {
    color: theme.palette.searchButtonBg,
    cursor: "pointer",
  },
}));

export const Text = styled("div")(({ theme }) => ({
  color: "#151515",
  marginLeft: 10,
  fontSize: 14,
  lineHeight: 1.5,
  fontWeight: 400,
  transition: "color 0.26s ease",
  userSelect: "none",
  textTransform: "uppercase",
}));

export const TextLanguage = styled("div")(({ theme }) => ({
  color: "#000000",
  fontSize: 14,
  fontWeight: 400,
  position: "relative",

  "&:hover": {
    color: theme.palette.searchButtonBg,
    cursor: "pointer",
  },

  "&.active": {
    color: theme.palette.searchButtonBg,
  },
}));

export const CheckboxBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
}));

export const Checkbox = styled("div")(({ theme }) => ({
  width: 15,
  height: 15,
  position: "relative",
  border: `3px solid #151515`,
  borderRadius: "50%",
  transition: "border-color 0.26s ease",
  backgroundColor: "#fff",

  "&:after": {
    content: "",
    width: 8,
    height: 8,
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "transparent",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    transition: "background-color 0.26s ease",
  },

  "&.active": {
    borderColor: "#151515",
  },
}));

export const Label = styled("label")(({ theme }) => ({
  color: "#151515",
  marginLeft: 5,
}));
